import React from 'react';
import { graphql, Link } from 'gatsby';
import { Box, Text } from '@chakra-ui/core';

import Layout from '../components/Layout';
import HeaderImage from '../components/HeaderImage';

const Post = ({ data }) => {
  return (
    <Box mb={6}>
      <Text fontSize="3xl" mb={2}>
        <Link to={`/writing/${data.slug}`}>{data.title}</Link>
      </Text>
      <Text fontSize="lg" dangerouslySetInnerHTML={{ __html: data.excerpt }} />
    </Box>
  );
};

const Writing = ({ data }) => (
  <Layout>
    <HeaderImage
      imageData={data.file.childImageSharp.fluid}
      backgroundPosition={'center 30%'}
      className="dimmed"
    >
      <Text textAlign="center" fontSize="6xl" font-weight="400" color="white">
        Writing
      </Text>
    </HeaderImage>
    {data.allWordpressPost.nodes.map(post => {
      return <Post key={post.id} data={post} />;
    })}
  </Layout>
);

export default Writing;

export const query = graphql`
  query PostsQuery {
    allWordpressPost {
      nodes {
        id
        title
        excerpt
        slug
      }
    }
    file(name: { eq: "writing" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
